import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import FieldCheckbox from '@react-form-fields/material-ui/components/Checkbox';
import FormValidation from '@react-form-fields/material-ui/components/FormValidation';
import FieldSelect from '@react-form-fields/material-ui/components/Select';
import { FormComponent, IStateForm } from 'components/Abstract/Form';
import Toast from 'components/Shared/Toast';
import IProperty, { enPropertyFeaturedImovelWeb } from 'interfaces/models/property';
import bindComponent from 'rxjs-operators/bindComponent';
import propertyService from 'services/property';

interface IState
  extends IStateForm<{
    id: number;
    featured: boolean;
    featuredChavesNaMao: boolean;
    featuredImovelWeb: enPropertyFeaturedImovelWeb;
  }> {
  opened: boolean;
  loading: boolean;
}

interface IProps {
  opened: boolean;
  onCompleted: (property: IProperty) => void;
  onCancel: (property: IProperty) => void;
  property: IProperty;
}

export default class PropertyFeaturedDialog extends FormComponent<IProps, IState> {
  featuredOptions = [
    { label: 'Simples', value: enPropertyFeaturedImovelWeb.simple },
    { label: 'Destaque', value: enPropertyFeaturedImovelWeb.featured },
    { label: 'Super Destaque', value: enPropertyFeaturedImovelWeb.superFeatured }
  ];

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    if (nextProps.opened && prevState.opened) {
      return prevState;
    }

    return {
      ...prevState,
      opened: nextProps.opened,
      model: {
        id: nextProps.property?.id,
        featured: nextProps.property?.featured,
        featuredChavesNaMao: nextProps.property?.featuredChavesNaMao,
        featuredImovelWeb: nextProps.property?.featuredImovelWeb
      }
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = { ...this.state, loading: false };
  }

  onCancel = () => {
    this.props.onCancel(this.props.property);
  };

  onSubmit = (isValid: boolean) => {
    if (!isValid) return;

    const { model } = this.state;
    this.setState({ loading: true });

    propertyService
      .featured(model)
      .pipe(bindComponent(this))
      .subscribe({
        next: () => {
          this.setState({ loading: false });

          Toast.show('Destaque salvo');
          this.props.onCompleted({ ...this.props.property, ...model });
        },
        error: err => {
          Toast.error(err);
          this.setState({ loading: false });
        }
      });
  };

  render() {
    const { opened, loading, model } = this.state;

    return (
      <Dialog disableEscapeKeyDown open={opened} onClose={this.resetForm}>
        {loading && <LinearProgress color='secondary' />}

        <FormValidation onSubmit={this.onSubmit} ref={this.bindForm}>
          <DialogTitle>Destaque</DialogTitle>

          <DialogContent>
            <FieldCheckbox
              label='Destaque Viva Real'
              disabled={loading}
              checked={model.featured}
              onChange={this.updateModel((model, v) => (model.featured = v))}
            />

            <FieldCheckbox
              label='Destaque Chaves na Mão'
              disabled={loading}
              checked={model.featuredChavesNaMao}
              onChange={this.updateModel((model, v) => (model.featuredChavesNaMao = v))}
            />

            <FieldSelect
              label='Destaque Imóvel Web'
              disabled={loading}
              value={model.featuredImovelWeb}
              options={this.featuredOptions}
              onChange={this.updateModel((model, v) => (model.featuredImovelWeb = v))}
            />
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={this.onCancel}>
              Cancelar
            </Button>
            <Button color='secondary' type='submit' disabled={loading}>
              Salvar
            </Button>
          </DialogActions>
        </FormValidation>
      </Dialog>
    );
  }
}
