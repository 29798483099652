import Tooltip from '@material-ui/core/Tooltip';
import { IStyledProps } from 'decorators/withStyles';
import IProperty from 'interfaces/models/property';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';

interface IntegrationsProps extends IStyledProps {
  property: IProperty;
}

const Integrations = ({ property, classes }: IntegrationsProps) => {
  return (
    <>
      <Tooltip title={property.disableSyncVivaReal ? 'Sincronização desativada' : 'Sincronização ativa'}>
        <div className={classes.syncIconWrapper}>
          {!property.disableSyncVivaReal ? (
            <CheckCircleIcon className={classes.syncIconOk} size={20} />
          ) : (
            <CloseCircleIcon className={classes.syncIconDisabed} size={20} />
          )}
          <span className={classes.syncIconText}>Viva Real</span>
        </div>
      </Tooltip>
      <Tooltip
        title={
          property.disableSyncImovelWeb
            ? 'Sincronização desativada'
            : property.propertyImovelWebCategoryId
            ? 'Sincronização ativa'
            : 'Necessário adicionar as categorias da Imóvel Web'
        }
      >
        <div className={classes.syncIconWrapper}>
          {property.propertyImovelWebCategoryId && !property.disableSyncImovelWeb ? (
            <CheckCircleIcon className={classes.syncIconOk} size={20} />
          ) : property.disableSyncImovelWeb ? (
            <CloseCircleIcon className={classes.syncIconDisabed} size={20} />
          ) : (
            <AlertCircleIcon className={classes.syncIconError} size={20} />
          )}
          <span className={classes.syncIconText}>Imóvel Web</span>
        </div>
      </Tooltip>
      <Tooltip title={property.disableSyncChavesNaMao ? 'Sincronização desativada' : 'Sincronização ativa'}>
        <div className={classes.syncIconWrapper}>
          {property.propertyImovelWebCategoryId && !property.disableSyncChavesNaMao ? (
            <CheckCircleIcon className={classes.syncIconOk} size={20} />
          ) : property.disableSyncChavesNaMao ? (
            <CloseCircleIcon className={classes.syncIconDisabed} size={20} />
          ) : (
            <AlertCircleIcon className={classes.syncIconError} size={20} />
          )}
          <span className={classes.syncIconText}>Chaves na Mão</span>
        </div>
      </Tooltip>
      <Tooltip
        title={property.propertyOlxCategoryId ? 'Sincronização ativa' : 'Necessário adicionar as categorias da OLX'}
      >
        <div className={classes.syncIconWrapper}>
          {property.propertyOlxCategoryId ? (
            <CheckCircleIcon className={classes.syncIconOk} size={20} />
          ) : (
            <CloseCircleIcon className={classes.syncIconDanger} size={20} />
          )}
          <span className={classes.syncIconText}>Olx</span>
        </div>
      </Tooltip>
      <Tooltip title={property.disableSyncSiteIbs ? 'Sincronização desativada' : 'Sincronização ativa'}>
        <div className={classes.syncIconWrapper}>
          {!property.disableSyncSiteIbs ? (
            <CheckCircleIcon className={classes.syncIconOk} size={20} />
          ) : (
            <CloseCircleIcon className={classes.syncIconDisabed} size={20} />
          )}
          <span className={classes.syncIconText}>Site IBS</span>
        </div>
      </Tooltip>
      <Tooltip title={property.disableSyncSiteVision ? 'Sincronização desativada' : 'Sincronização ativa'}>
        <div className={classes.syncIconWrapper}>
          {!property.disableSyncSiteVision ? (
            <CheckCircleIcon className={classes.syncIconOk} size={20} />
          ) : (
            <CloseCircleIcon className={classes.syncIconDisabed} size={20} />
          )}
          <span className={classes.syncIconText}>Site Vision</span>
        </div>
      </Tooltip>
    </>
  );
};

export default Integrations;
