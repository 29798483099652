import { Fragment, PureComponent } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DropdownMenu from 'components/Shared/DropdownMenu';
import OptionItem from 'components/Shared/DropdownMenu/OptionItem';
import PermissionHide from 'components/Shared/PermissionHide';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import formatAcceptExchange from 'formatters/acceptExchange';
import { dateFormat } from 'formatters/date';
import { formatPrice, formatStatus, formatStatusIcon, formatTransactionType } from 'formatters/property';
import imageUrl from 'helpers/imageUrl';
import IProperty, { enPropertyStatus, enPropertyFeaturedImovelWeb } from 'interfaces/models/property';
import { enRoles } from 'interfaces/models/user';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import PlayCircleIcon from 'mdi-react/PlayCircleIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';

import Integrations from './Integrations';

interface IState {}

interface IProps extends IStyledProps {
  property: IProperty;
  onEdit: (property: IProperty) => void;
  onDelete: (property: IProperty) => void;
  onFeatured: (property: IProperty) => void;
  onDetails: (property: IProperty) => void;
  onUpdateStatus: (property: IProperty, newStatus: enPropertyStatus) => void;
}

@WithStyles(theme => ({
  img: {
    minWidth: '100%',
    width: 190,
    height: 160,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 'auto',
    position: 'relative'
  },
  grid: {
    minHeight: '100%',
    padding: 4,
    margin: 0
  },
  transaction: {
    position: 'absolute',
    top: 5,
    left: 5,
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
    background: 'rgba(0, 0, 0, 0.67)',
    padding: '4px 8px',
    borderRadius: 3
  },
  price: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: 5,
    position: 'absolute',
    fontSize: 19,
    color: 'white',
    letterSpacing: 1,
    background: 'linear-gradient(180deg, transparent, rgba(0, 0, 0, .87))'
  },
  featuredIcon: {
    color: theme.variables.colors.featured
  },
  statusIcon: {
    verticalAlign: 'text-bottom'
  },
  statusIconcompleted: {
    color: theme.variables.colors.statusCompleted
  },
  statusIconcanceled: {
    color: theme.variables.colors.statusCanceled
  },
  syncIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 8
  },
  syncIconText: {
    fontSize: 11,
    marginLeft: 4
  },
  syncIconOk: {
    color: theme.variables.colors.statusCompleted
  },
  syncIconError: {
    opacity: 0.5
  },
  syncIconDisabed: {
    color: theme.variables.colors.statusCanceled
  },
  itemFooter: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  integrationWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}))
export default class PropertyListItem extends PureComponent<IProps, IState> {
  onDetails = () => this.props.onDetails(this.props.property);
  onFeatured = () => this.props.onFeatured(this.props.property);

  onEdit = () => this.props.onEdit(this.props.property);
  onDelete = () => this.props.onDelete(this.props.property);
  onComplete = () => this.props.onUpdateStatus(this.props.property, enPropertyStatus.completed);
  onCancel = () => this.props.onUpdateStatus(this.props.property, enPropertyStatus.canceled);
  onReopen = () => this.props.onUpdateStatus(this.props.property, enPropertyStatus.pending);

  render() {
    const { property, classes } = this.props;
    const Icon = formatStatusIcon(property.status);

    return (
      <Fragment>
        <Divider />

        <Grid container>
          <Grid item xs={12} sm='auto'>
            <div className={classes.img} style={{ backgroundImage: `url(${imageUrl(property.images[0])})` }}>
              <div className={classes.transaction}>{formatTransactionType(property.transactionType)}</div>
              <div className={classes.price}>{formatPrice(property)}</div>
            </div>
          </Grid>
          <Grid item xs={true}>
            <Grid container direction='column' className={classes.grid} spacing={2}>
              <Grid item xs={true}>
                <Typography variant='subtitle1' gutterBottom>
                  <strong>{property.title} </strong>
                  <small>
                    {property.propertyType.display} •{' '}
                    {property.disableDuplication
                      ? '1 endereço (não duplicar)'
                      : property.locationCount > 1
                      ? `${property.locationCount} endereços`
                      : '1 endereço'}
                  </small>
                </Typography>
                <PermissionHide role={enRoles.admin}>
                  <Typography variant='caption' component='p'>
                    <strong>Classificação do Proprietário: </strong>
                    {property.propertyOwner?.ownerType?.title ?? <i>sem classificação</i>}
                  </Typography>
                  <Typography variant='caption' component='p'>
                    <strong>Interesse: </strong>
                    {formatAcceptExchange(property)} •{' '}
                    {property.propertyOwner?.acceptPropertyType?.display ?? <i>Sem preferência</i>}
                    {property.propertyOwner?.acceptLocation ? ` • ${property.propertyOwner?.acceptLocation}` : ''}
                  </Typography>
                </PermissionHide>
              </Grid>
              <Grid item xs={false}>
                <div className={classes.itemFooter}>
                  <div>
                    <Typography variant='caption'>
                      <Icon
                        size={17}
                        className={`${classes.statusIcon} ${classes[`statusIcon${property.status}`] || ''}`}
                      />
                      &nbsp;{formatStatus(property.status)} • &nbsp;Atualizado em: {dateFormat(property.updatedDate)}
                      &nbsp; • &nbsp;
                    </Typography>
                  </div>
                  <div className={classes.integrationWrapper}>
                    <Integrations property={property} classes={classes} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false}>
            <Grid container>
              {property.status === enPropertyStatus.pending && (
                <Grid item xs={false}>
                  <Tooltip title='Visualização Rápida'>
                    <IconButton onClick={this.onDetails}>
                      <EyeIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={false}>
                <DropdownMenu>
                  <OptionItem text='Editar' icon={EditIcon} handler={this.onEdit} />
                  <OptionItem
                    text='Destaque'
                    icon={
                      property.featured ||
                      property.featuredChavesNaMao ||
                      property.featuredImovelWeb !== enPropertyFeaturedImovelWeb.simple
                        ? StarIcon
                        : StarOutlineIcon
                    }
                    handler={this.onFeatured}
                  />
                  <OptionItem text='Excluir' icon={DeleteIcon} handler={this.onDelete} />
                  {property.status === enPropertyStatus.pending && (
                    <OptionItem text='Finalizar' icon={CheckCircleIcon} handler={this.onComplete} />
                  )}
                  {property.status === enPropertyStatus.pending && (
                    <OptionItem text='Cancelar' icon={CloseCircleIcon} handler={this.onCancel} />
                  )}
                  {property.status !== enPropertyStatus.pending && (
                    <OptionItem text='Reabrir' icon={PlayCircleIcon} handler={this.onReopen} />
                  )}
                </DropdownMenu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
